<template>
	<div
		class="top-line-primary"
	>
		<div class="mt-30  width-480 ma-auto flex-column full-height overflow-y-auto">
			<div
				class="mt-10"
			>
				<h6>연락처</h6>
				<div class="flex-row justify-space-between">
					<div class="flex-2">
						<input
							v-model="item.member_tell"
							type="number" placeholder="연락처를 입력하세요"
							class="input-underline"
							:rules="[$rules.max(item, 'member_tell', 11)]"
							:disabled="is_member_tell"
						/>
					</div>
					<div
						class="flex-1 under-line-ddd"
					>
						<button
							v-if="!item.is_on_sms_confirm"
							@click="postSmsAuth"
							class="btn btn-primary"
							:disabled="is_sms_auth"
						>인증요청</button>
						<button
							v-else
							@click="resetSmsAuth"
							class="btn btn-primary"
						>다시하기</button>
					</div>
				</div>
			</div>

			<div
				class="mt-10"
				v-if="item.is_on_sms_confirm"
			>
				<h6>SMS 인증</h6>
				<div
					v-if="!item.is_sms_confirm"
					class="flex-row justify-space-between"
				>
					<div class="flex-2">
						<input
							v-model="item.sms_code"
							type="number" placeholder="인증코드를 입력하세요"
							class="input-underline"
							:rules="[$rules.max(item, 'sms_code', 6)]"
						/>
					</div>
					<div class="flex-1">
						<button
							@click="postSmsConfirm"
							class="btn btn-primary"
						>인증확인</button>
					</div>
				</div>
				<div
					v-else
					class="input-underline color-success"
				>
					인증이 완료되었습니다.
				</div>
			</div>
		</div>
		<div
			class="mt-30 text-center"
		>
			<button
				class="btn btn-fill"
				@click="save"
				:disabled="!item.is_sms_confirm"
			>연락처 변경</button>
		</div>
	</div>
</template>

<script>

export default{
	name: 'PhoneNumber'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '연락처 변경'
				,top: false
				,title: true
				,bottom: true
				, top_line: true
				,class: 'blue'
			}
			,item: {
				member_id: this.user.member_id
				, member_tell: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
			}
			,view: {
				result: false
			}
			,isSave: true
		}
	}
	, computed: {
		is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = false
			}
			return t
		}
		, is_member_tell: function(){
			let t = false
			if(this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = true
			}
			return t
		}
	}
	,methods: {
		resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				const result = await this.$request.init({
					method: 'post'
					,url: '/auth/postSmsAuth'
					,data: {
						member_tell: this.item.member_tell
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: '/auth/postSmsConfirm'
					,data: {
						member_tell: this.item.member_tell
						, sms_code: this.item.sms_code
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, save: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: '/member/postPhoneNumber'
					,data: {
						member_tell: this.item.member_tell
						, is_sms_confirm: this.item.is_sms_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('to', { name: 'MyPage'})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}

</script>

<style>
.content input[type=text] { width: 100%; border-bottom: 1px solid #ddd; padding: 10px 0;}
</style>